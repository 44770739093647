import React from 'react';
import { Card, Switch, Space, Form, Row, Col, InputNumber } from 'antd';

const VarWeaveSettings = ({
  enable,
  vwhccw,
  vwvccw,
  vwhis,
  vwvis,
  vwhil,
  vwvil,
  onValueChange,
  onStateChange,
}) => {
  return (
    <Card
      title={
        <div>
          <Space size="middle">
            <span>宽窄带</span>
            <Switch
              checkedChildren="开启"
              unCheckedChildren="关闭"
              checked={enable}
              onChange={onStateChange}
            />
          </Space>
        </div>
      }
      size="small"
      type="inner"
      bodyStyle={enable ? {} : { padding: '0' }}
    >
      {enable && (
        <Form>
          <Row>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="针座/扩展1">
                <Switch
                  checkedChildren="反转"
                  unCheckedChildren="正转"
                  checked={vwhccw === 1}
                  onChange={(check) => {
                    onValueChange('vwhccw', check ? 1 : 0);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="钢扣/扩展2">
                <Switch
                  checkedChildren="反转"
                  unCheckedChildren="正转"
                  checked={vwvccw === 1}
                  onChange={(check) => {
                    onValueChange('vwvccw', check ? 1 : 0);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="针座起始针">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={vwhis + 1}
                  onChange={(val) => {
                    onValueChange('his', val - 1);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="针座针长度">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={vwhil}
                  onChange={(val) => {
                    onValueChange('hil', val);
                  }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="针座结束针">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={vwhis + vwhil}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="钢扣起始针">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={vwvis + 1}
                  onChange={(val) => {
                    onValueChange('vis', val - 1);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="钢扣针长度">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={vwvil}
                  onChange={(val) => {
                    onValueChange('vil', val);
                  }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="钢扣结束针">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={vwvis + vwvil}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default VarWeaveSettings;
