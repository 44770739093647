import React from 'react';
import { Card, Switch, Space, Form, Row, Col, InputNumber } from 'antd';

const OxHornSettings = ({
  enable,
  oxhccw,
  oxvccw,
  oxis,
  oxil,
  onValueChange,
  onStateChange,
}) => {
  return (
    <Card
      title={
        <div>
          <Space size="middle">
            <span>牛角带</span>
            <Switch
              checkedChildren="开启"
              unCheckedChildren="关闭"
              checked={enable}
              onChange={onStateChange}
            />
          </Space>
        </div>
      }
      size="small"
      type="inner"
      bodyStyle={enable ? {} : { padding: '0' }}
    >
      {enable && (
        <Form>
          <Row>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="左右/扩展1">
                <Switch
                  checkedChildren="反转"
                  unCheckedChildren="正转"
                  checked={oxhccw === 1}
                  onChange={(check) => {
                    onValueChange('oxhccw', check ? 1 : 0);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="上下/扩展2">
                <Switch
                  checkedChildren="反转"
                  unCheckedChildren="正转"
                  checked={oxvccw === 1}
                  onChange={(check) => {
                    onValueChange('oxvccw', check ? 1 : 0);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="起始针">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={oxis + 1}
                  onChange={(val) => {
                    onValueChange('nis', val - 1);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="针长度">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={oxil}
                  onChange={(val) => {
                    onValueChange('nil', val);
                  }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="结束针">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={oxis + oxil}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default OxHornSettings;
