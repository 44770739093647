import React, { useState, useEffect } from 'react';
import { Button, Card, Space, Col, Row, Form, InputNumber, Radio } from 'antd';
import MotorSettings from './components/MotorSettings';
import DragonMap from './components/DragonMap';
import VarWeaveSettings from './components/VarWeaveSettings';
import OxHornSettings from './components/OxHornSettings';
import './App.css';

const defaultParams = {
  n: 320,
  o: 5,
  dm: [],
  mt: 1,

  ropr: 251.32,
  rorr: 77,
  romr: 10000,
  roccw: 0,
  rod: 0,
  rois: 0,
  roil: 0,

  thpr: 117.8,
  thrr: 3,
  thmr: 10000,
  thccw: 0,
  thd: 0,
  this: 0,
  thil: 0,

  twpr: 141.37,
  twrr: 2,
  twmr: 10000,
  twccw: 1,
  twd: 0,
  twis: 0,
  twil: 0,

  bwpr: 141.37,
  bwrr: 2,
  bwmr: 10000,
  bwccw: 1,
  bwd: 0,
  bwis: 0,
  bwil: 0,

  dgpr: 132.24,
  dgrr: 1,
  dgmr: 10000,
  dgccw: 0,
  dgd: 0,
  dgis: 0,
  dgil: 0,

  e1pr: 117.8,
  e1rr: 3,
  e1mr: 10000,
  e1ccw: 0,
  e1d: 0,
  e1is: 0,
  e1il: 0,

  e2pr: 132.24,
  e2rr: 1,
  e2mr: 10000,
  e2ccw: 0,
  e2d: 0,
  e2is: 0,
  e2il: 0,

  vwhccw: 0,
  vwhis: 0,
  vwhil: 0,

  vwvccw: 1,
  vwvis: 0,
  vwvil: 0,

  oxhccw: 0,
  oxvccw: 0,
  oxis: 0,
  oxil: 0,

  msrr: 1,
  msmr: 2000,
  msccw: 0,
  msis: 0,
  msil: 0,
  msmrpm: 1000,

  swcto: 8,
};

const dragonMapOptions = [
  { label: '类型1', value: 1 },
  { label: '类型2', value: 2 },
  { label: '类型3', value: 3 },
];

const moduleTypeOptions = [
  { label: '单通道', value: 0 },
  { label: '双通道', value: 1 },
];

function App() {
  const [needles, setNeedles] = useState(defaultParams.n);
  const [ports, setPorts] = useState(defaultParams.o);
  const [sensorCount, setSensorCount] = useState(defaultParams.swcto);
  const [dragonMapType, setDragonMapType] = useState(1);
  const [moduleType, setModuleType] = useState(defaultParams.mt);
  const [dragonMap, setDragonMap] = useState([]);

  const [mainMotorEnable, setMainMotorEnable] = useState(false);
  const [mainMotorCCW, setMainMotorCCW] = useState(defaultParams.msccw);
  const [mainMotorResolution, setMainMotorResolution] = useState(
    defaultParams.msmr,
  );
  const [mainMotorReductionRatio, setMainMotorReductionRatio] = useState(
    defaultParams.msrr,
  );
  const [mainMotorMaxRPM, setMainMotorMaxRPM] = useState(defaultParams.msmrpm);

  const [rollerMotorEnable, setRollerMotorEnable] = useState(false);
  const [rollerMotorCCW, setRollerMotorCCW] = useState(defaultParams.roccw);
  const [rollerMotorPerimeter, setRollerMotorPerimeter] = useState(
    defaultParams.ropr,
  );
  const [rollerMotorResolution, setRollerMotorResolution] = useState(
    defaultParams.romr,
  );
  const [rollerMotorReductionRatio, setRollerMotorReductionRatio] = useState(
    defaultParams.rorr,
  );

  const [threadMotorEnable, setThreadMotorEnable] = useState(false);
  const [threadMotorCCW, setThreadMotorCCW] = useState(defaultParams.thccw);
  const [threadMotorPerimeter, setThreadMotorPerimeter] = useState(
    defaultParams.thpr,
  );
  const [threadMotorResolution, setThreadMotorResolution] = useState(
    defaultParams.thmr,
  );
  const [threadMotorReductionRatio, setThreadMotorReductionRatio] = useState(
    defaultParams.thrr,
  );

  const [topWeftMotorEnable, setTopWeftMotorEnable] = useState(false);
  const [topWeftMotorCCW, setTopWeftMotorCCW] = useState(defaultParams.twccw);
  const [topWeftMotorPerimeter, setTopWeftMotorPerimeter] = useState(
    defaultParams.twpr,
  );
  const [topWeftMotorResolution, setTopWeftMotorResolution] = useState(
    defaultParams.twmr,
  );
  const [topWeftMotorReductionRatio, setTopWeftMotorReductionRatio] = useState(
    defaultParams.twrr,
  );

  const [bottomWeftMotorEnable, setBottomWeftMotorEnable] = useState(false);
  const [bottomWeftMotorCCW, setBottomWeftMotorCCW] = useState(
    defaultParams.bwccw,
  );
  const [bottomWeftMotorPerimeter, setBottomWeftMotorPerimeter] = useState(
    defaultParams.bwpr,
  );
  const [bottomWeftMotorResolution, setBottomWeftMotorResolution] = useState(
    defaultParams.bwmr,
  );
  const [bottomWeftMotorReductionRatio, setBottomWeftMotorReductionRatio] =
    useState(defaultParams.bwrr);

  const [deliveryGlueMotorEnable, setDeliveryGlueMotorEnable] = useState(false);
  const [deliveryGlueMotorCCW, setDeliveryGlueMotorCCW] = useState(
    defaultParams.dgccw,
  );
  const [deliveryGlueMotorPerimeter, setDeliveryGlueMotorPerimeter] = useState(
    defaultParams.dgpr,
  );
  const [deliveryGlueMotorResolution, setDeliveryGlueMotorResolution] =
    useState(defaultParams.dgmr);
  const [deliveryGlueMotorReductionRatio, setDeliveryGlueMotorReductionRatio] =
    useState(defaultParams.dgrr);

  const [e1MotorEnable, setE1MotorEnable] = useState(false);
  const [e1MotorCCW, setE1MotorCCW] = useState(defaultParams.e1ccw);
  const [e1MotorPerimeter, setE1MotorPerimeter] = useState(defaultParams.e1pr);
  const [e1MotorResolution, setE1MotorResolution] = useState(
    defaultParams.e1mr,
  );
  const [e1MotorReductionRatio, setE1MotorReductionRatio] = useState(
    defaultParams.e1rr,
  );

  const [e2MotorEnable, setE2MotorEnable] = useState(false);
  const [e2MotorCCW, setE2MotorCCW] = useState(defaultParams.e2ccw);
  const [e2MotorPerimeter, setE2MotorPerimeter] = useState(defaultParams.e2pr);
  const [e2MotorResolution, setE2MotorResolution] = useState(
    defaultParams.e2mr,
  );
  const [e2MotorReductionRatio, setE2MotorReductionRatio] = useState(
    defaultParams.e2rr,
  );

  const [vwEnable, setVwEnable] = useState(false);
  const [vwhccw, setVwhccw] = useState(defaultParams.vwhccw);
  const [vwvccw, setVwvccw] = useState(defaultParams.vwvccw);

  const [oxEnable, setOxEnable] = useState(false);
  const [oxhccw, setOxhccw] = useState(defaultParams.oxhccw);
  const [oxvccw, setOxvccw] = useState(defaultParams.oxvccw);

  const [rois, setRois] = useState(0);
  const [roil, setRoil] = useState(0);
  const [thris, setThris] = useState(0);
  const [thril, setThril] = useState(0);
  const [twis, setTwis] = useState(0);
  const [twil, setTwil] = useState(0);
  const [twd, setTwd] = useState(0);
  const [bwis, setBwis] = useState(0);
  const [bwil, setBwil] = useState(0);
  const [bwd, setBwd] = useState(0);
  const [dgis, setDgis] = useState(0);
  const [dgil, setDgil] = useState(0);
  const [dgd, setDgd] = useState(0);
  const [e1is, setE1is] = useState(0);
  const [e1il, setE1il] = useState(0);
  const [e2is, setE2is] = useState(0);
  const [e2il, setE2il] = useState(0);
  const [e2d, setE2d] = useState(0);
  const [vwhis, setVwhis] = useState(0);
  const [vwhil, setVwhil] = useState(0);
  const [vwvis, setVwvis] = useState(0);
  const [vwvil, setVwvil] = useState(0);
  const [oxis, setOxis] = useState(0);
  const [oxil, setOxil] = useState(0);
  const [msis, setMsis] = useState(0);
  const [msil, setMsil] = useState(0);

  const [rotype, setRotype] = useState(0);
  const [thtype, setThtype] = useState(0);

  useEffect(() => {
    let offset = needles;
    if (rollerMotorEnable) {
      setRois(offset);
      if (rotype) {
        setRoil(1);
        offset += 1;
      } else {
        setRoil(4);
        offset += 4;
      }
    } else {
      setRois(0);
      setRoil(0);
    }
    if (threadMotorEnable) {
      setThris(offset);
      if (thtype) {
        setThril(2);
        offset += 2;
      } else {
        setThril(4);
        offset += 4;
      }
    } else {
      setThris(0);
      setThril(0);
    }
    if (topWeftMotorEnable) {
      setTwis(offset);
      if (vwEnable) {
        setTwil(40);
        offset += 40;
        setTwd(0);
      } else {
        setTwil(4);
        offset += 4;
        setTwd(offset);
        offset += 1;
      }
    } else {
      setTwis(0);
      setTwil(0);
      setTwd(0);
    }
    if (bottomWeftMotorEnable) {
      setBwis(offset);
      if (vwEnable) {
        setBwil(40);
        offset += 40;
        setBwd(0);
      } else {
        setBwil(4);
        offset += 4;
        setBwd(offset);
        offset += 1;
      }
    } else {
      setBwis(0);
      setBwil(0);
      setBwd(0);
    }
    if (deliveryGlueMotorEnable) {
      setDgis(offset);
      setDgil(4);
      offset += 4;
      setDgd(offset);
      offset += 1;
    } else {
      setDgis(0);
      setDgil(0);
      setDgd(0);
    }
    if (e1MotorEnable) {
      setE1is(offset);
      setE1il(4);
      offset += 4;
    } else {
      setE1is(0);
      setE1il(0);
    }
    if (e2MotorEnable) {
      setE2is(offset);
      setE2il(4);
      offset += 4;
      setE2d(offset);
      offset += 1;
    } else {
      setE2is(0);
      setE2il(0);
      setE2d(0);
    }
    if (vwEnable) {
      setVwhis(offset);
      setVwhil(40);
      offset += 40;
      setVwvis(offset);
      setVwvil(40);
      offset += 40;
    } else {
      setVwhis(0);
      setVwhil(0);
      setVwvis(0);
      setVwvil(0);
    }
    if (oxEnable) {
      setOxis(offset);
      setOxil(4);
      offset += 4;
    } else {
      setOxis(0);
      setOxil(0);
    }
    if (mainMotorEnable) {
      setMsis(offset);
      setMsil(4);
      offset += 4;
    } else {
      setMsis(0);
      setMsil(0);
    }
  }, [
    needles,
    rotype,
    thtype,
    rollerMotorEnable,
    threadMotorEnable,
    topWeftMotorEnable,
    bottomWeftMotorEnable,
    deliveryGlueMotorEnable,
    e1MotorEnable,
    e2MotorEnable,
    vwEnable,
    mainMotorEnable,
    oxEnable,
  ]);

  useEffect(() => {
    const n = needles / ports;
    // 判断 n 是否为整数
    if (!Number.isInteger(n) || n < 8 || n % 8 !== 0) {
      console.log('针数或输出端口数不正确');
      // Modal.error({
      //   title: '提示',
      //   content: '针数或输出端口数不正确',
      // });
      return;
    }

    let dm = [];
    const numRows = n / 8;

    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < 8; col++) {
        let val = 0;
        if (dragonMapType === 1) {
          val =
            (col % 2) +
            (numRows - row - 1) * 2 +
            (4 - parseInt(col / 2) - 1) * 2 * numRows;
        } else if (dragonMapType === 2) {
          val = n - (col * numRows + row) - 1;
        } else if (dragonMapType === 3) {
          val =
            (col % 4) +
            (numRows - row - 1) * 4 +
            (2 - parseInt(col / 4) - 1) * 4 * numRows;
        }
        dm.push(val);
      }
    }

    setDragonMap(dm);
  }, [needles, ports, dragonMapType]);

  const onDragonMapTypeChange = ({ target: { value } }) => {
    const dmt = parseInt(value);
    setDragonMapType(dmt);

    /*
    使用js,生成一个整数数组dm, 数组长度为 n, 并且为8的倍数
    dmt的值为1、2、3，根据dmt 生成3种类型的数组排列,假定n=64
    当 dmt 为 1 时，dm的内容为
    const dm = [
      62,63,46,47,30,31,14,15,
      60,61,44,45,28,29,12,13,
      58,59,42,43,26,27,10,11,
      56,57,40,41,24,25,8,9,
      54,55,38,39,22,23,6,7,
      52,53,36,37,20,21,4,5,
      50,51,34,35,18,19,2,3,
      48,49,32,33,16,17,0,1
    ]
    当 dmt 为 2 时，dm的内容为
    const dm = [
      63,55,47,39,31,23,15,7,
      62,54,46,38,30,22,14,6,
      61,53,45,37,29,21,13,5,
      60,52,44,36,28,20,12,4,
      59,51,43,35,27,19,11,3,
      58,50,42,34,26,18,10,2,
      57,49,41,33,25,17,9,1,
      56,48,40,32,24,16,8,0
    ]
    当 dmt 为 3 时，dm的内容为
    const dm = [
      60,61,62,63,28,29,30,31,
      56,57,58,59,24,25,26,27,
      52,53,54,55,20,21,22,23,
      48,49,50,51,16,17,18,19,
      44,45,46,47,12,13,14,15,
      40,41,42,43,8,9,10,11,
      36,37,38,39,4,5,6,7,
      32,33,34,35,0,1,2,3
    ]
*/
    // 下面是代码
    // const n = needles / ports;
    // // 判断 n 是否为整数
    // if (!Number.isInteger(n) || n < 8 || n % 8 !== 0) {
    //   console.log('针数或输出端口数不正确');
    //   Modal.error({
    //     title: '提示',
    //     content: '针数或输出端口数不正确',
    //   });
    //   return;
    // }

    // let dm = [];
    // const numRows = n / 8;

    // for (let row = 0; row < numRows; row++) {
    //   for (let col = 0; col < 8; col++) {
    //     let val = 0;
    //     if (dmt === 1) {
    //       val =
    //         (col % 2) +
    //         (numRows - row - 1) * 2 +
    //         (4 - parseInt(col / 2) - 1) * 2 * numRows;
    //     } else if (dmt === 2) {
    //       val = n - (col * numRows + row) - 1;
    //     } else if (dmt === 3) {
    //       val =
    //         (col % 4) +
    //         (numRows - row - 1) * 4 +
    //         (2 - parseInt(col / 4) - 1) * 4 * numRows;
    //     }
    //     dm.push(val);
    //   }
    // }

    // setDragonMap(dm);
  };

  const onMainMotorStateChange = (check) => {
    setMainMotorEnable(check);
  };

  const onMainMotorValChange = (label, val) => {
    if (label === 'ccw') {
      setMainMotorCCW(val);
    } else if (label === 'resolution') {
      setMainMotorResolution(val);
    } else if (label === 'reducationRatio') {
      setMainMotorReductionRatio(val);
    } else if (label === 'maxRPM') {
      setMainMotorMaxRPM(val);
    }
  };

  const onRollerMotorStateChange = (check) => {
    setRollerMotorEnable(check);
  };

  const onRollerMotorValChange = (label, val) => {
    if (label === 'ccw') {
      setRollerMotorCCW(val);
    } else if (label === 'perimeter') {
      setRollerMotorPerimeter(val);
    } else if (label === 'resolution') {
      setRollerMotorResolution(val);
    } else if (label === 'reducationRatio') {
      setRollerMotorReductionRatio(val);
    } else if (label === 'nis') {
      setRois(val);
    } else if (label === 'nil') {
      setRoil(val);
    }
  };

  const onRollerTypeChange = (val) => {
    setRotype(val);
  };

  const onThreadMotorStateChange = (check) => {
    setThreadMotorEnable(check);
  };

  const onThreadMotorValChange = (label, val) => {
    if (label === 'ccw') {
      setThreadMotorCCW(val);
    } else if (label === 'perimeter') {
      setThreadMotorPerimeter(val);
    } else if (label === 'resolution') {
      setThreadMotorResolution(val);
    } else if (label === 'reducationRatio') {
      setThreadMotorReductionRatio(val);
    } else if (label === 'nis') {
      setThris(val);
    } else if (label === 'nil') {
      setThril(val);
    }
  };

  const onThreadTypeChange = (val) => {
    setThtype(val);
  };

  const onTopWeftMotorStateChange = (check) => {
    setTopWeftMotorEnable(check);
  };

  const onTopWeftMotorValChange = (label, val) => {
    if (label === 'ccw') {
      setTopWeftMotorCCW(val);
    } else if (label === 'perimeter') {
      setTopWeftMotorPerimeter(val);
    } else if (label === 'resolution') {
      setTopWeftMotorResolution(val);
    } else if (label === 'reducationRatio') {
      setTopWeftMotorReductionRatio(val);
    } else if (label === 'nis') {
      setTwis(val);
    } else if (label === 'nil') {
      setTwil(val);
    } else if (label === 'nid') {
      setTwd(val);
    }
  };

  const onBottomWeftMotorStateChange = (check) => {
    setBottomWeftMotorEnable(check);
  };

  const onBottomWeftMotorValChange = (label, val) => {
    if (label === 'ccw') {
      setBottomWeftMotorCCW(val);
    } else if (label === 'perimeter') {
      setBottomWeftMotorPerimeter(val);
    } else if (label === 'resolution') {
      setBottomWeftMotorResolution(val);
    } else if (label === 'reducationRatio') {
      setBottomWeftMotorReductionRatio(val);
    } else if (label === 'nis') {
      setBwis(val);
    } else if (label === 'nil') {
      setBwil(val);
    } else if (label === 'nid') {
      setBwd(val);
    }
  };

  const onDeliveryGlueMotorStateChange = (check) => {
    setDeliveryGlueMotorEnable(check);
  };

  const onDeliveryGlueMotorValChange = (label, val) => {
    if (label === 'ccw') {
      setDeliveryGlueMotorCCW(val);
    } else if (label === 'perimeter') {
      setDeliveryGlueMotorPerimeter(val);
    } else if (label === 'resolution') {
      setDeliveryGlueMotorResolution(val);
    } else if (label === 'reducationRatio') {
      setDeliveryGlueMotorReductionRatio(val);
    } else if (label === 'nis') {
      setDgis(val);
    } else if (label === 'nil') {
      setDgil(val);
    } else if (label === 'nid') {
      setDgd(val);
    }
  };

  const onE1MotorStateChange = (check) => {
    setE1MotorEnable(check);
  };

  const onE1MotorValChange = (label, val) => {
    if (label === 'ccw') {
      setE1MotorCCW(val);
    } else if (label === 'perimeter') {
      setE1MotorPerimeter(val);
    } else if (label === 'resolution') {
      setE1MotorResolution(val);
    } else if (label === 'reducationRatio') {
      setE1MotorReductionRatio(val);
    } else if (label === 'nis') {
      setE1is(val);
    } else if (label === 'nil') {
      setE1il(val);
    }
  };

  const onE2MotorStateChange = (check) => {
    setE2MotorEnable(check);
  };

  const onE2MotorValChange = (label, val) => {
    if (label === 'ccw') {
      setE2MotorCCW(val);
    } else if (label === 'perimeter') {
      setE2MotorPerimeter(val);
    } else if (label === 'resolution') {
      setE2MotorResolution(val);
    } else if (label === 'reducationRatio') {
      setE2MotorReductionRatio(val);
    } else if (label === 'nis') {
      setE2is(val);
    } else if (label === 'nil') {
      setE2il(val);
    } else if (label === 'nid') {
      setE2d(val);
    }
  };

  const onVwStateChange = (check) => {
    if (check) {
      setOxEnable(false);
    }
    setVwEnable(check);
  };

  const onVwValChange = (label, val) => {
    if (label === 'vwhccw') {
      setVwhccw(val);
    } else if (label === 'vwvccw') {
      setVwvccw(val);
    } else if (label === 'his') {
      setVwhis(val);
    } else if (label === 'hil') {
      setVwhil(val);
    } else if (label === 'vis') {
      setVwvis(val);
    } else if (label === 'vil') {
      setVwvil(val);
    }
  };

  const onOxStateChange = (check) => {
    if (check) {
      setVwEnable(false);
    }
    setOxEnable(check);
  };

  const onOxValChange = (label, val) => {
    if (label === 'oxhccw') {
      setOxhccw(val);
    } else if (label === 'oxvccw') {
      setOxvccw(val);
    } else if (label === 'nis') {
      setOxis(val);
    } else if (label === 'nil') {
      setOxil(val);
    }
  };

  const exportJsonFile = () => {
    const data = {
      n: needles,
      o: ports,
      dm: dragonMap,
      mt: moduleType,

      ropr: rollerMotorPerimeter,
      rorr: rollerMotorReductionRatio,
      romr: rollerMotorEnable ? rollerMotorResolution : 0,
      roccw: rollerMotorCCW,
      rod: 0,
      rois: rois,
      roil: roil,

      thpr: threadMotorPerimeter,
      thrr: threadMotorReductionRatio,
      thmr: threadMotorEnable ? threadMotorResolution : 0,
      thccw: threadMotorCCW,
      thd: 0,
      this: thris,
      thil: thril,

      twpr: topWeftMotorPerimeter,
      twrr: topWeftMotorReductionRatio,
      twmr: topWeftMotorEnable ? topWeftMotorResolution : 0,
      twccw: topWeftMotorCCW,
      twd: twd,
      twis: twis,
      twil: twil,

      bwpr: bottomWeftMotorPerimeter,
      bwrr: bottomWeftMotorReductionRatio,
      bwmr: bottomWeftMotorEnable ? bottomWeftMotorResolution : 0,
      bwccw: bottomWeftMotorCCW,
      bwd: bwd,
      bwis: bwis,
      bwil: bwil,

      dgpr: deliveryGlueMotorPerimeter,
      dgrr: deliveryGlueMotorReductionRatio,
      dgmr: deliveryGlueMotorEnable ? deliveryGlueMotorResolution : 0,
      dgccw: deliveryGlueMotorCCW,
      dgd: dgd,
      dgis: dgis,
      dgil: dgil,

      e1pr: e1MotorPerimeter,
      e1rr: e1MotorReductionRatio,
      e1mr: e1MotorEnable ? e1MotorResolution : 0,
      e1ccw: e1MotorCCW,
      e1d: 0,
      e1is: e1is,
      e1il: e1il,

      e2pr: e2MotorPerimeter,
      e2rr: e2MotorReductionRatio,
      e2mr: e2MotorEnable ? e2MotorResolution : 0,
      e2ccw: e2MotorCCW,
      e2d: e2d,
      e2is: e2is,
      e2il: e2il,

      vwhccw: vwhccw,
      vwhis: vwhis,
      vwhil: vwhil,

      vwvccw: vwvccw,
      vwvis: vwvis,
      vwvil: vwvil,

      oxhccw: oxhccw,
      oxvccw: oxvccw,
      oxis: oxis,
      oxil: oxil,

      msrr: mainMotorReductionRatio,
      msmr: mainMotorEnable ? mainMotorResolution : 0,
      msccw: mainMotorCCW,
      msis: msis,
      msil: msil,

      swcto: sensorCount,
    };

    // let offset = needles;
    // if (rollerMotorEnable) {
    //   data.rois = offset;
    //   data.roil = 4;
    //   offset += 4;
    // }
    // if (threadMotorEnable) {
    //   data.this = offset;
    //   data.thil = 4;
    //   offset += 4;
    // }
    // if (topWeftMotorEnable) {
    //   data.twis = offset;
    //   if (vwEnable) {
    //     data.twil = 40;
    //     offset += 40;
    //   } else {
    //     data.twil = 4;
    //     offset += 4;
    //     data.twd = offset;
    //     offset += 1;
    //   }
    // }
    // if (bottomWeftMotorEnable) {
    //   data.bwis = offset;
    //   if (vwEnable) {
    //     data.bwil = 40;
    //     offset += 40;
    //   } else {
    //     data.bwil = 4;
    //     offset += 4;
    //     data.bwd = offset;
    //     offset += 1;
    //   }
    // }
    // if (deliveryGlueMotorEnable) {
    //   data.dgis = offset;
    //   data.dgil = 4;
    //   offset += 4;
    //   data.dgd = offset;
    //   offset += 1;
    // }
    // if (e1MotorEnable) {
    //   data.e1is = offset;
    //   data.e1il = 4;
    //   offset += 4;
    // }
    // if (e2MotorEnable) {
    //   data.e2is = offset;
    //   data.e2il = 4;
    //   offset += 4;
    //   data.e2d = offset;
    //   offset += 1;
    // }
    // if (vwEnable) {
    //   data.vwhis = offset;
    //   data.vwhil = 40;
    //   offset += 40;
    //   data.vwvis = offset;
    //   data.vwvil = 40;
    //   offset += 40;
    // }
    // if (oxEnable) {
    //   data.oxis = offset;
    //   data.oxil = 4;
    //   offset += 4;
    // }
    // if (mainMotorEnable) {
    //   data.msis = offset;
    //   data.msil = 4;
    //   offset += 4;
    // }

    const fileName = 'config.json';
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = href;
    link.click();
    link.remove();
  };

  return (
    <div className="App">
      <Space
        direction="vertical"
        size="middle"
        style={{ display: 'flex', padding: '20px' }}
      >
        <Card
          title="参数设定"
          extra={
            <Button type="primary" onClick={exportJsonFile}>
              导出配置文件
            </Button>
          }
          style={{ width: '100%' }}
        >
          <Row>
            <Col span={10}>
              <Form.Item label="龙头针数">
                <InputNumber
                  onChange={(val) => {
                    setNeedles(parseInt(val));
                  }}
                  value={needles}
                  style={{ width: '60%' }}
                  controls={false}
                />
              </Form.Item>
              <Form.Item label="输出端口数">
                <InputNumber
                  onChange={(val) => {
                    setPorts(parseInt(val));
                  }}
                  value={ports}
                  style={{ width: '60%' }}
                  controls={false}
                />
              </Form.Item>
              <Form.Item label="圆盘每纬出发次数">
                <InputNumber
                  onChange={(val) => {
                    setSensorCount(parseInt(val));
                  }}
                  value={sensorCount}
                  style={{ width: '60%' }}
                  controls={false}
                />
              </Form.Item>

              <Form.Item label="模块类型">
                <Radio.Group
                  options={moduleTypeOptions}
                  value={moduleType}
                  onChange={(e) => {
                    setModuleType(parseInt(e.target.value));
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Form.Item label="排针表">
                <Radio.Group
                  options={dragonMapOptions}
                  value={dragonMapType}
                  onChange={onDragonMapTypeChange}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Row>
                <Col span={20}>
                  <DragonMap dragonMap={dragonMap} />
                </Col>
              </Row>
            </Col>
            <Col span={14}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <MotorSettings
                  title="滚筒"
                  enable={rollerMotorEnable}
                  ccw={rollerMotorCCW}
                  perimeter={rollerMotorPerimeter}
                  resolution={rollerMotorResolution}
                  reducationRatio={rollerMotorReductionRatio}
                  ctrlType={rotype}
                  ctrlLabel={['多段', '停桥']}
                  nis={rois}
                  nil={roil}
                  onStateChange={onRollerMotorStateChange}
                  onValueChange={onRollerMotorValChange}
                  onCtrlTypeChange={onRollerTypeChange}
                />
                <MotorSettings
                  title="底线"
                  enable={threadMotorEnable}
                  ccw={threadMotorCCW}
                  perimeter={threadMotorPerimeter}
                  resolution={threadMotorResolution}
                  reducationRatio={threadMotorReductionRatio}
                  ctrlType={thtype}
                  ctrlLabel={['4针', '2 针']}
                  nis={thris}
                  nil={thril}
                  onStateChange={onThreadMotorStateChange}
                  onValueChange={onThreadMotorValChange}
                  onCtrlTypeChange={onThreadTypeChange}
                />
                <MotorSettings
                  title="上送纬"
                  enable={topWeftMotorEnable}
                  ccw={topWeftMotorCCW}
                  perimeter={topWeftMotorPerimeter}
                  resolution={topWeftMotorResolution}
                  reducationRatio={topWeftMotorReductionRatio}
                  nis={twis}
                  nil={twil}
                  nid={twd}
                  nidEnale={!vwEnable}
                  onStateChange={onTopWeftMotorStateChange}
                  onValueChange={onTopWeftMotorValChange}
                />
                <MotorSettings
                  title="下送纬"
                  enable={bottomWeftMotorEnable}
                  ccw={bottomWeftMotorCCW}
                  perimeter={bottomWeftMotorPerimeter}
                  resolution={bottomWeftMotorResolution}
                  reducationRatio={bottomWeftMotorReductionRatio}
                  nis={bwis}
                  nil={bwil}
                  nid={bwd}
                  nidEnale={!vwEnable}
                  onStateChange={onBottomWeftMotorStateChange}
                  onValueChange={onBottomWeftMotorValChange}
                />
                <MotorSettings
                  title="送胶"
                  enable={deliveryGlueMotorEnable}
                  ccw={deliveryGlueMotorCCW}
                  perimeter={deliveryGlueMotorPerimeter}
                  resolution={deliveryGlueMotorResolution}
                  reducationRatio={deliveryGlueMotorReductionRatio}
                  nis={dgis}
                  nil={dgil}
                  nid={dgd}
                  nidEnale={true}
                  onStateChange={onDeliveryGlueMotorStateChange}
                  onValueChange={onDeliveryGlueMotorValChange}
                />
                <MotorSettings
                  title="底线2/扩展3"
                  enable={e1MotorEnable}
                  ccw={e1MotorCCW}
                  perimeter={e1MotorPerimeter}
                  resolution={e1MotorResolution}
                  reducationRatio={e1MotorReductionRatio}
                  nis={e1is}
                  nil={e1il}
                  onStateChange={onE1MotorStateChange}
                  onValueChange={onE1MotorValChange}
                />
                <MotorSettings
                  title="送胶2/扩展4"
                  enable={e2MotorEnable}
                  ccw={e2MotorCCW}
                  perimeter={e2MotorPerimeter}
                  resolution={e2MotorResolution}
                  reducationRatio={e2MotorReductionRatio}
                  nis={e2is}
                  nil={e2il}
                  nid={e2d}
                  onStateChange={onE2MotorStateChange}
                  onValueChange={onE2MotorValChange}
                />
                <VarWeaveSettings
                  enable={vwEnable}
                  vwhccw={vwhccw}
                  vwvccw={vwvccw}
                  vwhis={vwhis}
                  vwhil={vwhil}
                  vwvis={vwvis}
                  vwvil={vwvil}
                  onStateChange={onVwStateChange}
                  onValueChange={onVwValChange}
                />
                <MotorSettings
                  title="主电机"
                  mainMotor={true}
                  enable={mainMotorEnable}
                  ccw={mainMotorCCW}
                  maxRPM={mainMotorMaxRPM}
                  resolution={mainMotorResolution}
                  reducationRatio={mainMotorReductionRatio}
                  nis={msis}
                  nil={msil}
                  onStateChange={onMainMotorStateChange}
                  onValueChange={onMainMotorValChange}
                />
                <OxHornSettings
                  enable={oxEnable}
                  oxhccw={oxhccw}
                  oxvccw={oxvccw}
                  oxis={oxis}
                  oxil={oxil}
                  onStateChange={onOxStateChange}
                  onValueChange={onOxValChange}
                />
              </Space>
            </Col>
          </Row>
        </Card>
      </Space>
    </div>
  );
}

export default App;
