import React from "react";
import { Row, Col } from "antd";

const DragonMap = ({ dragonMap }) => {
  const genMaptable = () => {
    const rows = [];
    const colLen = dragonMap.length / 8;
    for (let i = 0; i < colLen; i++) {
      const cols = [];
      for (let j = 0; j < 8; j++) {
        cols.push(
          <Col
            key={"col" + i + j}
            span={3}
            style={{
              borderLeft: "1px solid",
              borderTop: "1px solid",
              padding: "5px 5px",
              borderRight: j === 7 ? "1px solid" : "",
              borderBottom: i === colLen - 1 ? "1px solid" : "",
            }}
          >
            {dragonMap[i * 8 + j]}
          </Col>
        );
      }
      rows.push(<Row key={"row" + i}>{cols}</Row>);
    }
    return rows;
  };

  return (
    <div
      style={{
        textAlign: "center",
        margin: "10px 30px",
      }}
    >
      {genMaptable()}
    </div>
  );
};

export default DragonMap;
