import React from 'react';
import { Card, Switch, Space, Form, InputNumber, Row, Col } from 'antd';

const MotorSettings = ({
  enable,
  mainMotor,
  title,
  perimeter,
  resolution,
  reducationRatio,
  ctrlType,
  ctrlLabel,
  ccw,
  maxRPM,
  onValueChange,
  onStateChange,
  onCtrlTypeChange,
  nis,
  nil,
  nid,
  nidEnale,
}) => {
  return (
    <Card
      title={
        <div>
          <Space size="middle">
            <span>{title}</span>
            <Switch
              checkedChildren="开启"
              unCheckedChildren="关闭"
              checked={enable}
              onChange={onStateChange}
            />
            {enable && (
              <Space size="middle">
                <Switch
                  checkedChildren="反转"
                  unCheckedChildren="正转"
                  checked={ccw === 1}
                  onChange={(check) => {
                    onValueChange('ccw', check ? 1 : 0);
                  }}
                />
                {ctrlLabel && (
                  <Switch
                    checkedChildren={ctrlLabel[1]}
                    unCheckedChildren={ctrlLabel[0]}
                    checked={ctrlType === 1}
                    onChange={(check) => {
                      onCtrlTypeChange(check ? 1 : 0);
                    }}
                  />
                )}
              </Space>
            )}
          </Space>
        </div>
      }
      size="small"
      type="inner"
      bodyStyle={enable ? {} : { padding: '0' }}
    >
      {enable && (
        <Form>
          <Row>
            {perimeter >= 0 && (
              <Col span={8} style={{ padding: '0 10px' }}>
                <Form.Item label="周长">
                  <InputNumber
                    controls={false}
                    style={{ width: '100%' }}
                    value={perimeter}
                    onChange={(val) => {
                      onValueChange('perimeter', val);
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="分辨率">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={resolution}
                  onChange={(val) => {
                    onValueChange('resolution', val);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ padding: '0 10px' }}>
              <Form.Item label="减速比">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={reducationRatio}
                  onChange={(val) => {
                    onValueChange('reducationRatio', val);
                  }}
                />
              </Form.Item>
            </Col>
            {mainMotor && (
              <Col span={8} style={{ padding: '0 10px' }}>
                <Form.Item label="最大转速">
                  <InputNumber
                    controls={false}
                    style={{ width: '100%' }}
                    value={maxRPM}
                    onChange={(val) => {
                      onValueChange('maxRPM', val);
                    }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={nidEnale ? 6 : 8} style={{ padding: '0 10px' }}>
              <Form.Item label="起始针">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={nis + 1}
                  onChange={(val) => {
                    onValueChange('nis', val - 1);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={nidEnale ? 6 : 8} style={{ padding: '0 10px' }}>
              <Form.Item label="针长度">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={nil}
                  onChange={(val) => {
                    onValueChange('nil', val);
                  }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={nidEnale ? 6 : 8} style={{ padding: '0 10px' }}>
              <Form.Item label="结束针">
                <InputNumber
                  controls={false}
                  style={{ width: '100%' }}
                  value={nil + nis}
                  disabled
                />
              </Form.Item>
            </Col>
            {nidEnale && (
              <Col span={6} style={{ padding: '0 10px' }}>
                <Form.Item label="方向针">
                  <InputNumber
                    controls={false}
                    style={{ width: '100%' }}
                    value={nid + 1}
                    onChange={(val) => {
                      onValueChange('nid', val - 1);
                    }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default MotorSettings;
